import React from "react"
import {graphql} from "gatsby";
import Layout from "../../components/layout/layout"
import Seo from "../../components/layout/seo"
import {Officer} from "../../components/content_type/Officers/Officer";


const OfficerPage = ({data}) => {
  const edge = data.officer.edges
  return (
    <Layout>
      <Seo title="Officers" keywords={[`gatsby`, `application`, `react`]}/>
      <>
        {edge.map((person, index) => (
          <Officer key={index} data={person} format={"long"}/>
        ))}
      </>
    </Layout>
  )
}

export default OfficerPage

export const query = graphql`
    query officer($slug: Int, $gid: Int) {
        officer: allGroupContentLodgeGroupNodeOfficer (filter: {drupal_internal__id: {eq: $slug}}){
            edges {
                node {
                    ...officerFragment
                }
            }
        }
        lodge: groupLodge (drupal_internal__id: {eq: $gid}) {
            ...lodgeFragment
        }
    }
`